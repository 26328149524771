export const translations = [
	{
		id: "pl",
		slug: "pl",
		default: true,
		name: {
			pl: "Polski",
			en: "Polish",
		},
	},
];
