import { computed, toRaw } from "vue";
import useRouter from "@@/composables/route/useRouter";
import useAsideState from "@@/composables/aside/useAsideState";

export default () => {
	const { asideState, isOpenAside } = useAsideState();

	const setParent = (parent) => {
		asideState.parent = parent;
	};

	const getParent = computed(() => {
		const { currentRoute, currentRouteHasParent } = useRouter();

		const routeParent = computed(() => {
			if (!currentRoute.value.matched) return false;
			if (currentRoute.value.matched.length <= 1) return false;

			return currentRoute.value.matched[currentRoute.value.matched.length - 2];
		});

		let theParent = toRaw(routeParent.value);

		if (
			asideState.parent &&
			asideState.parent.name !== undefined &&
			asideState.parent.name !== currentRoute.value.name &&
			currentRouteHasParent(asideState.parent.name)
		)
			theParent = toRaw(asideState.parent);

		if (!theParent || typeof theParent !== "object" || !("name" in theParent)) return null;

		if (!theParent) return null;

		if (!theParent.query || !("scrollBehavior" in theParent.query)) {
			if (currentRoute.value.query && "scrollBehavior" in currentRoute.value.query) {
				if (!theParent.query) theParent.query = {};
				theParent.query.scrollBehavior = currentRoute.value.query.scrollBehavior;
			}
		}

		return {
			name: theParent.name,
			params: theParent.params ? theParent.params : {},
			query: theParent.query ? theParent.query : {},
		};
	});

	const redirectToParent = async () => {
		if (!getParent.value) return false;
		return useRouter().push(getParent.value);
	};

	const redirectToParentIfShould = async () => {
		if (isOpenAside.value) return false;

		return redirectToParent();
	};

	return {
		setParent,
		getParent,
		redirectToParent,
		redirectToParentIfShould,
	};
};
