import { computed } from "vue";
import { useAuthStore } from "@/stores/useAuthStore";
import useAlertAdd from "@@/composables/alert/useAlertAdd.js";
import useFormValidateFromBacked from "@@/composables/form/useFormValidateFromBacked";

export default () => {
	const authStore = useAuthStore();

	const { addSuccessAlert, addErrorAlert } = useAlertAdd();
	const { setBackendValidation } = useFormValidateFromBacked();

	const user = computed(() => {
		return authStore.getUser;
	});

	const userRoleId = computed(() => {
		return authStore.getUserRoleId;
	});

	const update = async (data, validationActions) => {
		try {
			const response = await authStore.update(data);
			addSuccessAlert($i18n.global.t("auth.update.alert.save.ok"));
			return response;
		} catch (response) {
			addErrorAlert($i18n.global.t("auth.update.alert.save.error"));
			setBackendValidation(validationActions, response);
			throw new Error(response);
		}
	};

	const clearUser = () => {
		authStore.clearUser();
		return true;
	};

	return {
		authStore,
		user,
		userRoleId,
		update,
		clearUser,
	};
};
