import { PermissionEnum } from "@/enums/PermissionEnum.js";

const adminRoutes = [
	{
		name: "admin.users",
		path: "users",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.users.index",
		},
		meta: {
			permissions: PermissionEnum.ADMIN__USERS__READ,
		},
		children: [
			{
				name: "admin.users.index",
				path: "",
				component: () => import("@/views/pages/users/UsersIndex.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.users.admin.index",
				path: "admin",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnum.ADMIN__USERS__READ,
					breadcrumbs: {
						hidden: true,
					},
				},
				redirect: {
					name: "admin.users.admin.list",
				},
				children: [
					{
						name: "admin.users.admin.list",
						path: "list",
						component: () => import("@/views/pages/users/admin/UsersAdminListing.vue"),
						children: [
							{
								name: "admin.users.admin.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/admin/UsersAdminAdd.vue"),
								},
							},
							{
								name: "admin.users.admin.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/admin/UsersAdminEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "admin.users.sales_representative.index",
				path: "sales-representative",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnum.ADMIN__USERS__READ,
					breadcrumbs: {
						hidden: true,
					},
				},
				redirect: {
					name: "admin.users.sales_representative.list",
				},
				children: [
					{
						name: "admin.users.sales_representative.list",
						path: "list",
						component: () => import("@/views/pages/users/sales_representative/UsersSalesRepresentativeListing.vue"),
						children: [
							{
								name: "admin.users.sales_representative.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/sales_representative/UsersSalesRepresentativeAdd.vue"),
								},
							},
							{
								name: "admin.users.sales_representative.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/sales_representative/UsersSalesRepresentativeEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "admin.users.internal_employee.index",
				path: "internal-employee",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnum.ADMIN__USERS__READ,
					breadcrumbs: {
						hidden: true,
					},
				},
				redirect: {
					name: "admin.users.internal_employee.list",
				},
				children: [
					{
						name: "admin.users.internal_employee.list",
						path: "list",
						component: () => import("@/views/pages/users/internal_employee/UsersInternalEmployeeListing.vue"),
						children: [
							{
								name: "admin.users.internal_employee.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/internal_employee/UsersInternalEmployeeAdd.vue"),
								},
							},
							{
								name: "admin.users.internal_employee.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/internal_employee/UsersInternalEmployeeEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "admin.users.userLogs",
				path: "user-logs",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnum.ADMIN__USER_LOGS__READ,
					breadcrumbs: {
						hidden: true,
					},
				},
				redirect: {
					name: "admin.users.userLogs.list",
				},
				children: [
					{
						name: "admin.users.userLogs.list",
						path: "list",
						component: () => import("@/views/pages/users/user_logs/UsersUserLogsListing.vue"),
					},
				],
			},
		],
	},
	{
		name: "admin.settings.index",
		path: "settings",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.ADMIN__SETTINGS__UPDATE],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "admin.settings.mail",
		},
		children: [
			{
				name: "admin.settings.mail",
				path: "mail",
				component: () => import("@/views/pages/settings/SettingsMail.vue"),
			},
			{
				name: "admin.settingsEvent.index",
				path: "settings-event",
				component: () => import("@/views/pages/settings_event/SettingsEvent.vue"),
				meta: {
					permissions: [PermissionEnum.ADMIN__SETTINGS_EVENT__READ],
				},
			},
			{
				name: "admin.openingHours.index",
				path: "opening-hours",
				component: () => import("@/views/pages/settings/opening_hours/SettingsOpeningHours.vue"),
				meta: {
					permissions: [PermissionEnum.ADMIN__OPENING_HOURS__UPDATE],
				},
			},
			{
				name: "admin.openingHourLogs.index",
				path: "opening-hour-logs",
				component: () => import("@/views/pages/settings/opening_hour_logs/list/OpeningHourLogsListing.vue"),
				meta: {
					permissions: [PermissionEnum.ADMIN__OPENING_HOUR_LOGS__READ],
				},
			},
			{
				name: "admin.settings.openingHourNonStandard.index",
				path: "opening-hour-non-standard",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: [PermissionEnum.ADMIN__OPENING_HOUR_NON_STANDARDS__READ],
					breadcrumbs: {
						hidden: true,
					},
				},
				redirect: {
					name: "admin.settings.openingHourNonStandard.list",
				},
				children: [
					{
						name: "admin.settings.openingHourNonStandard.list",
						path: "list",
						component: () => import("@/views/pages/settings/opening_hour_non_standard/list/SettingsOpeningHourNonStandardListing.vue"),
						children: [
							{
								name: "admin.settings.openingHourNonStandard.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/settings/opening_hour_non_standard/SettingsOpeningHourNonStandardAdd.vue"),
									permissions: [PermissionEnum.ADMIN__OPENING_HOUR_NON_STANDARDS__CRUD],
								},
							},
							{
								name: "admin.settings.openingHourNonStandard.edit",
								path: "edit/:id?",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/settings/opening_hour_non_standard/SettingsOpeningHourNonStandardEdit.vue"),
									permissions: [PermissionEnum.ADMIN__OPENING_HOUR_NON_STANDARDS__CRUD],
								},
							},
						],
					},
				],
			},
		],
	},
	{
		name: "admin.rooms.index",
		path: "rooms",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.ADMIN__ROOMS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "admin.rooms.list",
		},
		children: [
			{
				name: "admin.rooms.list",
				path: "list",
				component: () => import("@/views/pages/rooms/list/RoomsListing.vue"),
				children: [
					{
						name: "admin.rooms.add",
						path: "add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/rooms/RoomsAdd.vue"),
							permissions: [PermissionEnum.ADMIN__ROOMS__CRUD],
						},
					},
					{
						name: "admin.rooms.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/rooms/RoomsEdit.vue"),
							permissions: [PermissionEnum.ADMIN__ROOMS__CRUD],
						},
					},
				],
			},
		],
	},
];

export default adminRoutes;
