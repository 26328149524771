import { mixed, string, addMethod } from "yup";

function empty() {
	// Funkcja musi być tak zadeklarowana, aby yup poprawnie ją interpretował
	return this.nullable().transform((value, originalValue) => {
		if (this.isType(value)) return value;

		value = String(originalValue).trim() === "" ? null : value;

		return value;
	});
}

addMethod(mixed, "empty", empty);
