export const PermissionEnum = {
	ADMIN__USERS__READ: "admin__users__read",
	ADMIN__USERS__CRUD: "admin__users__crud",
	ADMIN__USERS__READ__FILTER: "admin_users_read_filter",
	ADMIN__USER_LOGS__READ: "admin__user_logs__read",

	ADMIN__SETTINGS__READ: "admin__settings__read",
	ADMIN__SETTINGS__UPDATE: "admin__settings__update",

	ADMIN__ROOMS__CRUD: "admin__rooms__crud",
	ADMIN__ROOMS__READ: "admin__rooms__read",

	ADMIN__SETTINGS_EVENT__READ: "admin__settings_event__read",
	ADMIN__SETTINGS_EVENT__UPDATE: "admin__settings_event__update",

	ADMIN__OPENING_HOUR_NON_STANDARDS__CRUD: "admin__opening_hour_non_standards__crud",
	ADMIN__OPENING_HOUR_NON_STANDARDS__READ: "admin__opening_hour_non_standards__read",

	ADMIN__OPENING_HOUR_LOGS__READ: "admin__opening_hour_logs__read",

	ADMIN__OPENING_HOURS__READ: "admin__opening_hours__read",
	ADMIN__OPENING_HOURS__UPDATE: "admin__opening_hours__update",

	ADMIN__RESERVATIONS__UPDATE_STATUS: "admin__reservations__update_status",

	ADMIN__EVENTS__UPDATE__STATUS: "admin__events__update__status",

	SHARED__ROOMS__READ__FILTER: "shared__rooms__read__filter",

	SHARED__PARTNERS__READ: "shared__partners__read",
	SHARED__PARTNERS__CRUD: "shared__partners__crud",
	SHARED__PARTNERS__READ__FILTER: "shared__partners__read__filter",

	SHARED__RESERVATIONS__READ: "shared__reservations__read",
	SHARED__RESERVATIONS__CRUD: "shared__reservations__crud",

	SHARED__SURVEYS__READ: "shared__surveys__read",

	SHARED__EVENTS__CRUD: "shared__events__crud",
	SHARED__EVENTS__READ: "shared__events__read",
	SHARED__EVENTS__READ__DOWNLOAD_SURVEYS: "shared__events__crud__read_surveys",

	SHARED__EVENTS__CREATE__BASED_ON_RESERVATION: "shared__events__create__based_on_reservation",

	SHARED__EVENT_PARTICIPANTS__READ: "shared__event_participants__read",
	SHARED__EVENT_PARTICIPANTS__CREATE: "shared__event_participants__create",
	SHARED__EVENT_PARTICIPANTS__UPDATE: "shared__event_participants__update",
	SHARED__EVENT_PARTICIPANTS__UPDATE__DELETE_EVENT_PARTICIPANT_FROM_EVENT:
		"shared__event_participants__update__delete_event_participant_from_event",
	SHARED__EVENT_PARTICIPANTS__DELETE: "shared__event_participants__delete",

	SHARED__EVENT_PARTICIPANTS_EVENTS__CRUD__DOWNLOAD_INTERVIEW_SURVEY: "shared__event_participants_events__crud__download_interview_survey",

	SHARED__TIMETABLE__READ: "shared__timetable__read",
	SHARED__TIMETABLE_YEAR_EVENT_AND_RESERVATION__READ: "shared__timetable_year_event_and_reservation__read",

	APP__RESERVATIONS__CAN_SEE_LESS: "app__reservations__can_see_less",
	APP__RESERVATIONS__UPDATE__CAN_ONLY_UPDATE_HIS_OWN: "app__reservations__update__can_only_update_his_own",
	APP__RESERVATIONS__DELETE__CAN_ONLY_DELETE_HIS_OWN: "app__reservations__delete__can_only_delete_his_own",
	APP__RESERVATIONS__READ__CAN_ONLY_SEE_HIS_OWN: "app__reservations__read__can_only_see_his_own",

	APP__EVENTS__CAN_ONLY_ADD_INTERNAL: "app__events__can_only_add_internal",
	APP__EVENTS__UPDATE__CAN_ONLY_UPDATE_HIS_OWN: "app__events__update__can_only_update_his_own",
	APP__EVENTS__DELETE__CAN_ONLY_DELETE_HIS_OWN: "app__events__delete__can_only_delete_his_own",
	APP__EVENTS__CAN_ONLY_SEE_HIS_OWN: "app__events__can_only_see_his_own",
};
