import { PermissionEnum } from "@/enums/PermissionEnum.js";
const sharedRoutes = [
	{
		name: "dashboard",
		path: "dashboard",
		component: () => import("@/views/pages/dashboard/Dashboard.vue"),
		children: [
			{
				name: "dashboard.timetableCalendarEventPreview",
				path: "timetable/preview/:type?/:id?",
				component: () => import("@/views/Blank.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
					permissions: [PermissionEnum.SHARED__EVENTS__READ],
					aside: () => import("@/views/pages/timetable/TimetableEventPreview.vue"),
				},
			},
		],
	},
	{
		name: "profile",
		path: "profile",
		component: () => import("@/views/pages/profile/Profile.vue"),
	},
	{
		name: "timetable.index",
		path: "timetable",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__TIMETABLE__READ],
		},
		redirect: {
			name: "timetable.calendar",
		},
		children: [
			{
				name: "timetable.calendar",
				path: "",
				component: () => import("@/views/pages/timetable/Timetable.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
				children: [
					{
						name: "timetable.calendar.eventPreview",
						path: "preview/:type?/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							permissions: [PermissionEnum.SHARED__EVENTS__READ],
							aside: () => import("@/views/pages/timetable/TimetableEventPreview.vue"),
						},
					},
				],
			},
		],
	},
	{
		name: "partners.index",
		path: "partners",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__PARTNERS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "partners.list",
		},
		children: [
			{
				name: "partners.list",
				path: "list",
				component: () => import("@/views/pages/partners/list/PartnersListing.vue"),
				children: [
					{
						name: "partners.add",
						path: "add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/partners/PartnersAdd.vue"),
							permissions: [PermissionEnum.SHARED__PARTNERS__CRUD],
						},
					},
					{
						name: "partners.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/partners/PartnersEdit.vue"),
							permissions: [PermissionEnum.SHARED__PARTNERS__CRUD],
						},
					},
				],
			},
		],
	},
	{
		name: "events.index",
		path: "events",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__EVENTS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "events.list",
		},
		children: [
			{
				name: "events.list",
				path: "list",
				component: () => import("@/views/pages/events/list/EventsListing.vue"),
				meta: {
					permissions: [PermissionEnum.SHARED__EVENTS__READ],
				},
				children: [
					{
						name: "events.preview",
						path: "preview/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/preview/EventsPreview.vue"),
							permissions: [PermissionEnum.SHARED__EVENTS__READ],
						},
					},
					{
						name: "events.participants",
						path: ":id?/add/participants",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/participants/EventsParticipants.vue"),
							permissions: [PermissionEnum.SHARED__EVENTS__CRUD],
						},
					},
				],
			},
			{
				name: "events.internal.add",
				path: "internal/add/:id?",
				component: () => import("@/views/pages/events/EventsAddInternal.vue"),
				meta: {
					permissions: [PermissionEnum.SHARED__EVENTS__CRUD],
				},
			},
			{
				name: "events.traditional.add",
				path: "traditional/add/:id?/:fromReservation?",
				component: () => import("@/views/pages/events/EventsAddTraditional.vue"),
				meta: {
					permissions: [PermissionEnum.SHARED__EVENTS__CRUD],
				},
				children: [
					{
						name: "events.partners.add",
						path: "partners/add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/form/partners/EventsFormPartnersAdd.vue"),
							permissions: [PermissionEnum.SHARED__PARTNERS__CRUD],
						},
					},
					{
						name: "events.speakers.add",
						path: "speakers/add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/form/speakers/EventsFormSpeakersAdd.vue"),
						},
					},
					{
						name: "events.speakers.edit",
						path: "speakers/edit/:speakerId?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/form/speakers/EventsFormSpeakersEdit.vue"),
						},
					},
					{
						name: "events.agenda.add",
						path: "agenda/add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/form/agenda/EventsFormAgendaAdd.vue"),
						},
					},
					{
						name: "events.agenda.edit",
						path: "agenda/edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/form/agenda/EventsFormAgendaEdit.vue"),
						},
					},
				],
			},
			{
				name: "events.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/events/EventsEdit.vue"),
				meta: {
					permissions: [PermissionEnum.SHARED__EVENTS__CRUD],
				},
				children: [
					{
						name: "events.edit.partners.add",
						path: "partners/add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/form/partners/EventsFormPartnersAdd.vue"),
							permissions: [PermissionEnum.SHARED__PARTNERS__CRUD],
						},
					},
					{
						name: "events.edit.speakers.add",
						path: "speakers/add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/form/speakers/EventsFormSpeakersAdd.vue"),
						},
					},
					{
						name: "events.edit.speakers.edit",
						path: "speakers/edit/:speakerId?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/form/speakers/EventsFormSpeakersEdit.vue"),
						},
					},
					{
						name: "events.edit.agenda.add",
						path: "agenda/add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/form/agenda/EventsFormAgendaAdd.vue"),
						},
					},
					{
						name: "events.edit.agenda.edit",
						path: "agenda/edit/:agendaId?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/events/form/agenda/EventsFormAgendaEdit.vue"),
						},
					},
				],
			},
		],
	},
	{
		name: "eventParticipants.index",
		path: "event-participants",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__EVENT_PARTICIPANTS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "eventParticipants.list",
		},
		children: [
			{
				name: "eventParticipants.list",
				path: "list",
				component: () => import("@/views/pages/event_participants/list/EventParticipantsListing.vue"),
				children: [
					{
						name: "eventParticipants.add",
						path: "add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/event_participants/EventParticipantsAdd.vue"),
							permissions: [PermissionEnum.SHARED__EVENT_PARTICIPANTS__CREATE],
						},
					},
					{
						name: "eventParticipants.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/event_participants/EventParticipantsEdit.vue"),
							permissions: [PermissionEnum.SHARED__EVENT_PARTICIPANTS__UPDATE],
						},
					},
					{
						name: "eventParticipants.language",
						path: "language/:eventId?/:participantId?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/event_participants/language/EventParticipantsLanguage.vue"),
							permissions: [PermissionEnum.SHARED__EVENT_PARTICIPANTS__UPDATE],
						},
					},
				],
			},
			{
				name: "eventParticipants.agreements",
				path: "agreements/:id?",
				component: () => import("@/views/pages/event_participants/agreements/list/EventParticipantsAgreementsListing.vue"),
				meta: {
					permissions: [PermissionEnum.SHARED__EVENT_PARTICIPANTS__READ],
				},
			},
		],
	},
	{
		name: "surveys.index",
		path: "surveys",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__SURVEYS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "surveys.list",
		},
		children: [
			{
				name: "surveys.list",
				path: "list",
				component: () => import("@/views/pages/surveys/list/SurveysListing.vue"),
			},
		],
	},
	{
		name: "reservations.index",
		path: "reservations",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__RESERVATIONS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "reservations.list",
		},
		children: [
			{
				name: "reservations.list",
				path: "list",
				component: () => import("@/views/pages/reservations/list/ReservationsListing.vue"),
				children: [
					{
						name: "reservations.preview",
						path: "preview/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/reservations/preview/ReservationsPreview.vue"),
							permissions: [PermissionEnum.SHARED__RESERVATIONS__READ],
						},
					},
				],
			},
			{
				name: "reservations.add",
				path: "add",
				component: () => import("@/views/pages/reservations/ReservationsAdd.vue"),
				meta: {
					permissions: [PermissionEnum.SHARED__RESERVATIONS__CRUD],
				},
			},
			{
				name: "reservations.edit",
				path: "edit/:id?",
				component: () => import("@/views/pages/reservations/ReservationsEdit.vue"),
				meta: {
					permissions: [PermissionEnum.SHARED__RESERVATIONS__CRUD],
				},
			},
		],
	},
	{
		name: "agreements.index",
		path: "agreements",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__SURVEYS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "agreements.list",
		},
		children: [
			{
				name: "agreements.list",
				path: "list",
				component: () => import("@/views/pages/agreements/list/AgreementsListing.vue"),
			},
		],
	},
];

export default sharedRoutes;
