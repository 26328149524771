export const locales = [
	{
		id: "pl",
		slug: "pl",
		iso: "pl-PL",
		default: true,
		name: {
			pl: "Polski",
			en: "Polish",
		},
		dateTimeFormat: {
			date: "dd-MM-yyyy",
			time: "HH:mm:ss",
			dateTime: "dd-MM-yyyy HH:mm:ss",
		},
	},
];
