import SanctumMiddleware from "@@/middlewares/SanctumMiddleware";
import RouteExistsMiddleware from "@@/middlewares/RouteExistsMiddleware";
import LocaleExistsMiddleware from "@@/middlewares/LocaleExistsMiddleware";
import localeSelectedMiddleware from "@@/middlewares/LocaleSelectedMiddleware";
import AuthPassedMiddleware from "@@/middlewares/AuthPassedMiddleware";
import authRoutes from "@/routes/auth";
import errorRoutes from "@/routes/error";
import adminRoutes from "@/routes/admin";
import userRoutes from "@/routes/user";
import sharedRoutes from "@/routes/shared";
import guestRoutes from "@/routes/guest.js";

const routes = [
	{
		path: "",
		redirect: {
			name: "main",
			params: {
				locale: "pl",
			},
		},
	},
	{
		name: "main",
		path: `/:locale`,
		component: () => import("@/views/Index.vue"),
		meta: {
			middleware: [SanctumMiddleware, LocaleExistsMiddleware, localeSelectedMiddleware],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "dashboard",
		},
		children: [
			{
				name: "demo",
				path: "demo",
				component: () => import("@/views/pages/demo/Demo.vue"),
				meta: {
					middleware: [AuthPassedMiddleware],
					layout: "default",
				},
			},
			{
				name: "localeChoose",
				path: "language-choose",
				component: () => import("@/views/pages/LanguageChoose.vue"),
				meta: {
					layout: "clear",
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "auth",
				path: "",
				component: () => import("@/views/Blank.vue"),
				meta: {
					layout: "clear",
				},
				children: [...authRoutes],
			},
			{
				name: "app",
				path: "",
				component: () => import("@/views/Blank.vue"),
				meta: {
					middleware: [AuthPassedMiddleware],
					layout: "default",
					breadcrumbs: {
						hidden: true,
					},
				},
				children: [
					...sharedRoutes,
					{
						name: "admin",
						path: "admin",
						component: () => import("@/views/Blank.vue"),
						meta: {
							middleware: [AuthPassedMiddleware],
							layout: "default",
							breadcrumbs: {
								hidden: true,
							},
						},
						children: [...adminRoutes],
					},
					{
						name: "user",
						path: "user",
						component: () => import("@/views/Blank.vue"),
						meta: {
							middleware: [AuthPassedMiddleware],
							layout: "default",
						},
						children: [...userRoutes],
					},
				],
			},
			{
				name: "errors",
				path: "",
				component: () => import("@/views/Blank.vue"),
				meta: {
					layout: "error",
				},
				children: [...errorRoutes],
			},
		],
	},
	{
		name: "guest",
		path: "",
		component: () => import("@/views/Index.vue"),
		meta: {
			layout: "clear",
		},
		children: [...guestRoutes],
	},
	{
		path: "/:path(.*)*",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [LocaleExistsMiddleware, RouteExistsMiddleware],
		},
	},
];

export default routes;
